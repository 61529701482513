<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      title="修改密码"
      width="520px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="resetForm"
    >
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="请输入原密码" prop="password">
          <el-input v-model.trim="ruleForm.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请输入新密码" prop="newPassword">
          <el-input type="password" v-model.trim="ruleForm.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请确认新密码" prop="checkPass">
          <el-input type="password" v-model.trim="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm()">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { updatePwd } from '@/api/main/system/loginUser';
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      isShow: false,
      ruleForm: {
        password: '',
        newPassword: '',
        checkPass: ''
      },
      loading: false,
      rules: {
        password: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
        checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    init() {
      this.isShow = true;
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          return;
        }
        this.loading = true;
        const obj = {
          id: this.userInfo.id,
          ...this.ruleForm
        };
        updatePwd(obj)
          .then(res => {
            this.$message.success('修改成功！');
            this.resetForm();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.isShow = false;
    }
  }
};
</script>
