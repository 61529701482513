import { axios } from '@/utils/request';

/**
 * 修改密码
 */
export function updatePwd(data) {
  return axios({
    url: '/sysUser/updatePwd',
    method: 'post',
    data
  });
}
/**
 * 获取当前登录用户信息
 */
export function getUserInfo(params) {
  return axios({
    url: '/sysUser/onlineUserDetail',
    method: 'get',
    params
  });
}
