<template>
  <el-container class="basic-layout">
    <el-header style="height: 64px">
      <Header />
    </el-header>
    <el-container>
      <el-aside width="220px">
        <div class='aside-menu'>
          <el-menu
            unique-opened
            :default-openeds='openMenuArray'
            :default-active='$route.path'
            background-color='#272B38'
            text-color='#C0C4CE'
            active-text-color='#fff'
            router
          >
            <div>
              <template v-for='item in menuList'>
                <el-menu-item :index='item.path' :key='item.id'>
                  <i :class='item.icon'></i>
                  <span>{{ item.name }}</span>
                </el-menu-item>
              </template>
            </div>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <el-card>
          <div style="padding:16px;">
            <transition name="fade-transform" mode="out-in">
              <router-view />
            </transition>
          </div>
        </el-card>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import Header from '@/views/layout/Header';
export default {
  components: { Header },
  data() {
    return {
      menuList: [
        { id:1, name:'系统机构管理', icon:'iconfont icon-caidan9-1xitongjigouguanli', path: '/orgMana' },
        { id:2, name:'系统管理员管理', icon:'iconfont icon-caidan9-2xitongguanliyuanguanli', path: '/managerMana' },
      ],
      openMenuArray: [],
    };
  },
  created() {
  },

  methods: {

  }
};
</script>

<style lang="less" scoped>
.basic-layout {
  width: 100%;
  height: 100%;
  .el-header {
    padding: 0;
  }
  .el-container {
    .el-aside {
      background-color: #272b38;
    }
    .menu {
      height: calc(100vh - 198px);
      overflow-y: auto;
    }
    // 隐藏滚动条
    .menu::-webkit-scrollbar {
      display: none;
    }
    .el-main {
      height: calc(100vh - 64px);
      width: 100%;
      background-color: #f3f6fe;
      padding: 16px;
      .el-card {
        border: none;
        min-height: 100%;
        word-wrap: break-word;
        word-break: break-all;

        /deep/ .el-card__body {
          padding: 0;
        }
      }
    }
  }
  .aside-menu {
    width: 100%;
  }
  /deep/ .el-menu-item.is-active {
    background-color: #409dff !important;
  }
  .el-menu {
    border: none;
    padding-top: 10px;
  }
}
</style>
