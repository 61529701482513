<template>
  <div>
    <el-dropdown trigger="hover" class="systemChoice" @command="handleSelect" size="medium">
      <span class="el-dropdown-link">
        <i class="iconfont icon-header_gerenshezhi"></i>您好, {{ userInfo.name
        }}<i class="el-icon-caret-bottom el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item class="clearfix" command="updatePwd">
          <span>修改密码</span>
        </el-dropdown-item>
        <el-dropdown-item class="clearfix" v-if='!isOnlyOneSystem'>
          <span @click="operateSystem('register/systemselect')">切换系统</span>
        </el-dropdown-item>
        <el-dropdown-item class="clearfix">
          <span @click="operateSystem('login')">退出登录</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <edit-pwd ref="editFormRef"></edit-pwd>
  </div>
</template>

<script>
import { commonLoginOutAddress } from '@/mixins/commonLoginOutAddress.js';
import editPwd from '@/views/main/basicPage/_components/editPwd';
import { mapGetters } from 'vuex';
export default {
  components: {
    editPwd
  },
  mixins: [commonLoginOutAddress],
  computed: {
    ...mapGetters(['userInfo']),
    isOnlyOneSystem(){
      return this.$store.getters.appInfo.systemLength == 1
    }
  },
  methods: {
    // 修改密码
    handleSelect(command) {
      if (command === 'updatePwd') {
        this.$refs.editFormRef.init();
      }
    }
  }
};
</script>

<style scoped lang="less">
.systemChoice {
  font-size: 14px;
  margin: 0 16px 0 32px;
  cursor: pointer;
}
.el-dropdown-link {
  color: #fff;
  display: flex;
  align-items: center;
}
</style>

<style scoped>
.el-dropdown-menu--medium .el-dropdown-menu__item {
  padding: 0 10px;
}
.el-dropdown-menu__item {
  width: 100px;
  text-align: center;
}
</style>
