export const commonLoginOutAddress = {
  data() {
    return {
      webCommonEnvAddress: `${window.location.protocol}//${window.location.hostname}:8090/#/`,
      webCommonProAddress: 'http://admin.smartpark.zwehs.com/#/'
    };
  },
  methods: {
    //退出
    operateSystem(value) {
      this.$store.dispatch('loginOut').then(() => {
        // 开发环境
        if (process.env.NODE_ENV === 'development') {
          window.location.href = `${this.webCommonEnvAddress}${value}`;
        } else {
          // 生产环境 ${window.location.protocol}//
          window.location.href = `${this.webCommonProAddress}${value}`;
        }
      });
    }
  }
};
