<template>
  <div class="header">
    <div class="header-box">
      <div class="name">
        <div class="pro-title">全过程工程咨询项目管理子系统</div>
        <div class="menu-box">
          <el-menu
            :default-active='activeName'
            class='el-menu-demo'
            mode='horizontal'
            background-color='#117BF7'
            text-color='#fff'
            active-text-color='#fff'
            @select='handleSelect'>
            <el-menu-item v-for='item in menuList' :index='String(item.sort)' :key="item.id">{{ item.name }}
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class='operate-system'>
        <!-- 系统操作 -->
        <system-setting></system-setting>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import store from '@/utils/store';
import SystemSetting from 'components/header/SystemSetting';

export default {
  components: { SystemSetting },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    let name = sessionStorage.getItem('currentTab');
    if (name) {
      this.activeName = name;
      this.$emit('changeTab', parseInt(name));
    }
  },
  created() {
    this.menuList = store.getSession('allMenu');
  },
  data() {
    return {
      activeName: '1',
      menuList: []
    };
  },
  methods: {
    handleSelect(tab) {
      sessionStorage.setItem('currentTab', tab);
      store.removeSession('currentPath');
      const projectId = sessionStorage.getItem('projectId');
      switch (tab) {
        case '1':
          if (projectId) {
            this.$router.push('/center');
            this.$emit('changeTab', 1);
          } else {
            this.$router.push('/projectMana');
          }
          break;
        case '2':
          this.$router.push('/projectSetting');
          this.$emit('changeTab', 2);
          break;
        case '3':
          this.$router.push('/relatedMana');
          this.$emit('changeTab', 3);
          break;
        case '4':
          this.$router.push('/system');
          this.$emit('changeTab', 4);
          break;
        default:
          this.$router.push('/projectMana');
          break;
      }
    }
  }
};
</script>

<style lang='less' scoped>
.header {
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background-color: #117bf7;
    color: #fff;

    .operate-system {
      display: flex;
      align-items: center;

      .header-dispaly {
        display: flex;
        align-items: center;
      }
    }

    .name {
      display: flex;
      justify-content: flex-start;
      margin-left: 20px;
      .pro-title {
        line-height: 64px;
        color: #fff;
        font-style: oblique;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 30px;
        padding-right: 50px;
        min-width: 444px;
      }
    }
  }
}

/deep/ .el-menu.el-menu--horizontal {
  border: none;
}

/deep/ .el-menu-item {
  height: 64px;
  line-height: 64px;
  font-size: 14px;
  margin-right: 16px;

  &.is-active {
    font-size: 16px;
    font-weight: bold;
    background-color: #117BF7;
  }

  &:hover {
    background-color: #117BF7 !important;
  }
}
</style>
